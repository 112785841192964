.footer {
  color: @color-white;
  background-color: @color-gray-lighter;
  .clearfix();
  a {
    color: @color-white;
  }
}

.footer_left {
  padding: 20px 0 15px;
  min-height: 163px;
}

.footer_right {
  display: none;
}

.footer_designer {
  position: absolute;
  bottom: 15px;
  right: 23px;
  width: 53px;
  height: 21px;
}

@media (min-width: @screen-sm) {
  .footer {
    background-color: transparent;
    background-image: linear-gradient(to right, @color-gray-lighter 50%, transparent 50%);
  }

  .footer_right {
    display: block;
    padding-left: 21px;
  }

  .footer_left {
    min-height: 236px;
  }

  .footer_left,
  .footer_right {
    width: 50%;
    float: left;
    padding-top: 27px;
  }

  .footer_designer {
    right: 31px;
  }
}

@media (min-width: @screen-lg) {
  .footer {
    background: none;
  }

  .footer_left {
    background-color: @color-gray-lighter;
    padding-left: 30px;
    margin-top: 0;
    width: 300px;
    min-height: 197px;
  }

  .footer_right {
    width: calc(~"100% - 330px");
    position: absolute;
    left: 317px;
    height: 100%;
    padding-top: 82px;
  }

  .footer_designer {
    bottom: 16px;
    right: 55px;
  }
}