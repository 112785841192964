.post{
  margin-bottom: 45px;
}
.post_title{
  margin-top: 0;
}
.post-blogPage{
  margin-bottom: 0;
  &:last-child{
    margin-bottom: 24px;
  }
  .clearfix();

  .post_date{
    display: inline-block;
    float: left;
    width: 95px;
    color: @color-gray-darkest;
    font-weight: 500;
  }
  .post_title {
    display: inline-block;
    float: right;
    width: calc(~"100% - 110px");
  }
  .post_link{
    color: @color-gray-darkest;
    display: block;
    &:hover{
      color: @color-gray-lighter;
    }
  }
}
.post-events{
  .post_date{
    display: none;
  }
  .post_title{
    width: 100%;
    float: none;
  }
}
.post_dateCont{
  color: @color-gray-darkest;
  font-size: 0.75em;
}
.post_date,
.post_location{
  width: 200px;
}
.post_header{
  margin-bottom: 13px;
}

@media (min-width: @screen-lg){
  .post-blogPage:last-child{
    margin-bottom: 0;
  }
}