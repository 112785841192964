.search{
  position: absolute;
  right: 88px;
  top: 28px;
  z-index: 50;
}
.search_opener{
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  height: 37px;
  padding: 10px;
}
#search_form{
  position: absolute;
  top: 50px;
  right: 8px;
  width: 280px;
}
.search_input{
  padding: 12px 15px;
  border: none;
  width: 100%;
  border-radius: 0;
  vertical-align: middle;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background: @color-white 0 0;
  outline: none;
}
.search_buttonCont{
  display: none;
}

@media (min-width: @screen-lg){
  .search{
    right: 20px;
    top: 26px;
  }
  #search_form{
    top: 37px;
  }
}
@media (max-width: 433px){
  .search{

  }
}