.elemGrid{
  padding: 0;
  margin: 0;
  .clearfix();
}
.elemGrid_item{
  display: block;
  width: 100%;
  max-width: 33.3333%;
  float: left;
  margin-bottom: 18px;

  &:hover .elemGrid_image:before{
    opacity: 1;
  }

  &:nth-child(3n+1){
    .elemGrid_image-missing{
      background-color: @color-gray;
    }
  }
  &:nth-child(3n+2){
    .elemGrid_image-missing{
      background-color: @color-gray-darker;
    }
  }
  &:nth-child(3n+3){
    .elemGrid_image-missing{
      background-color: @color-gray-darkest;
    }
  }
}
.elemGrid_image{
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-bottom: 133.33333%;
  margin-bottom: 12px;

  &:before{
    content: '';
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: calc(~"100% - 20px");
    height: calc(~"100% - 20px");
    margin: auto;
    border: 1px solid @color-gray-lighter;
    transition: opacity @default-transition;
  }
}
.elemGrid_titleCont{
  min-height: 72px;
  display: block;
}
.elemGrid_title{
  display: block;
  color: @color-purple-lighter;
  text-align: center;
  font-weight: 500;
}
.elemGrid_subTitle{
  display: block;
  color: @color-gray-darkest;
  text-align: center;
}

@media (min-width: @screen-sm){
  .elemGrid_item{
    width: 25%;
  }
}
@media (min-width: @screen-lg){
  .elemGrid_item{
    width: 16.66666%;
  }
}