.sidebar{
  width: 100%;
  padding: 15px 0;
  position: relative;
  min-height: 81px;
}

@media (min-width: @screen-lg){
  .sidebar{
    /*padding: 15px;*/
    max-width: 300px;
    float: left;
  }
}