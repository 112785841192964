.gridBlock {
  position: relative;
}

.gridBlock_heading {
  position: absolute;
  top: -34px;
  font-size: 1em;
  color: @color-purple-lighter;
  padding: 0 30px;
  margin: 0;
}

.blog-page {
  .gridBlock_heading {
    position: relative;
    top: auto;
    margin: 20px 0 10px;
  }
  .gridBlock_item-showMore {
    display: none;
  }
}

.front-page {
  .gridBlock_heading {
    color: @color-gray-darker;
  }
}

.gridBlock_itemCont {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}

.gridBlock-reverseColors {
  .gridBlock_item {
    background-color: @color-gray-darker;
  }
  .gridBlock_showMore {
    background-color: @color-gray-lighter;
  }
  .gridBlock_image {
    background-color: @color-gray-lighter;
  }
}

.gridBlock-big {
  .gridBlock_heading {
    display: none;
  }
}

.gridBlock-floatItems {
  .clearfix();
  .gridBlock_item,
  .gridBlock_item:nth-child(even) {
    background-color: @color-gray-darkest;
  }
  .gridBlock_item {
    margin-bottom: 4px;
  }
}

.gridBlock_item {
  display: block;
  position: relative;
  background-color: @color-gray-lighter;
  .clearfix();

  &:nth-child(even) {
    background-color: @color-gray;
  }
}

.gridBlock_imageCont,
.gridBlock_info {
  width: 50%;
  float: left;
}

.gridBlock_imageCont {
  position: relative;
  padding-bottom: 33.68%;
}

.gridBlock_image {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: @color-gray-darker;
  height: 100%;
  width: 100%;
}

.gridBlock_info {
  padding: 20px 25px;
}

.gridBlock_title {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}

.gridBlock_title a {
  color: @color-white;
}

.gridBlock_date {
  position: absolute;
  font-size: 0.69em;
  color: @color-white;
  padding-right: 23px;
  bottom: 5px;
}

.gridBlock_item-showMore {
  background-color: transparent !important;
}

.gridBlock_showMore {
  display: block;
  width: 50%;
  background-color: @color-gray-darker;
  color: @color-white;
  float: right;
  padding: 13px 23px;
  position: relative;
  z-index: 1;
  &:hover {
    color: @color-white;
  }
}

.gridBlock-blogLayout {
  .gridBlock_heading {
    display: none;
  }
  .gridBlock_item {
    min-height: 9.375rem;
    margin-bottom: 0.25rem;
  }
  .gridBlock_imageCont {
    display: none;
  }
  .gridBlock_info {
    width: 100%;
  }
}

@media (min-width: @screen-xs) {
  .gridBlock-blogLayout {
    .gridBlock_item {
      width: 50%;
      float: left;
      min-height: 12.5rem;
    }
  }
}

@media (min-width: @screen-sm) {
  .gridBlock {
    font-size: 1.3125em;
  }

  .gridBlock_heading {
    top: -47px;
    /*font-size: 1.3125em;*/
    padding: 0 25px;
  }

  .gridBlock-big {
    .gridBlock_info {
      padding: 34px 33px;
    }
  }

  .gridBlock_info {
    /*padding: 25px 33px;*/
  }

  .gridBlock_date {
    padding-right: 25px;
    bottom: 13px;
  }

  .gridBlock_showMore {
    padding: 20px 23px;
  }
}

@media (min-width: @screen-lg) {
  .gridBlock {
    font-size: 1.125em;
  }

  .front-page .gridBlock-reverseColors {
    .gridBlock_heading {
      color: @color-white;
    }
  }

  .gridBlock-floatItems {
    .gridBlock_item {
      width: 50%;
      float: left;
    }
  }

  .gridBlock_heading {
    top: -30px;
  }

  .gridBlock-big {
    .gridBlock_title {
      font-size: 2em;
    }
  }

  .gridBlock_showMore {
    padding: 17px 30px;
  }

  .gridBlock-blogLayout {
    .gridBlock_item {
      width: 25%;
    }
  }
}