.contentBar{
  width: 100%;
  padding: 15px 0;
}

@media (min-width: @screen-lg){
  .contentBar{
    /*padding: 15px;*/
    max-width: calc(~"100% - 330px");
    float: right;
  }
}