.container {
  width: 100%;
  .clearfix();
  padding: 15px;
}
.innerContainer{
  max-width: 550px;
  margin: 0 auto;
}
.container-header{
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}
.container-footer{
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}
.front-page{
  .container-footer{
    padding: 0 30px;
  }
}
.container-noPaddingMobile{
  padding: 0;
}

.container-content{
  padding: 0;
  min-height: 200px;
  .clearfix();
}

@media (min-width: @screen-sm-min) {
  .container {
    max-width: @container-sm;
  }
  .container-noPaddingMobile{
    padding: 15px;
  }
  .container-content{
    position: relative;
    top: -40px;
    margin-bottom: -40px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: @screen-lg-min) {
  .container {
    max-width: @container-lg;
  }
  .container-content{
    top: 0;
    margin-bottom: 0;
    max-width: 1200px;
    z-index: 1;
  }
  .front-page{
    .container-footer{
      padding: 0 15px;
    }
  }
}