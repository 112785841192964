.menu_btn {
  background-color: transparent;
  position: absolute;
  top: 29px;
  right: 27px;
  border: none;
  width: 38px;
  height: 38px;
  padding: 0;
  z-index: 100;
  outline: none;

  &:before,
  &:after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: @color-white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 5px;
    opacity: 0;
  }
  &:after{
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &.open{
    &:before,
    &:after{
      opacity: 1;
    }
    img{
      opacity: 0;
    }
  }
  img{
    opacity: 1;
  }
}

.menu_main {
  display: none;
  visibility: hidden;
  width: 100%;
  background-color: @color-gray-darker;
  padding: 108px 0 30px;
  margin: 0;
  position: relative;
  z-index: 100;
  font-size: 1.5em;
  transition: opacity @default-transition, visibility @default-transition;
}

.js-menu_main-expanded {
  display: block;
  opacity: 0;
}
.js-menu_main-visible{
  visibility: visible;
  opacity: 1;
  transition: opacity @default-transition, visibility 0 0;
}

.menu_main_item {
  display: block;
  padding: 0 30px;
  text-transform: uppercase;
  position: relative;

  &.edit-btn {
    padding-left: 87px;
    padding-top: 16px;
  }

  &.opened {
    .menu_main_link {
      border-color: transparent;
    }
  }
}

.menu_main_link {
  display: block;
  color: @color-white;
  border-bottom: 2px solid @color-gray-lighter;
  padding: 13px 20px 14px 50px;
  transition: border-color @default-transition;

  &:hover,
  &:focus {
    color: @color-white;
  }

  &.active {
    font-weight: bold;
  }
}

.menu_collapseLink{
  position: absolute;
  top: 29px;
  right: 35px;
  width: 43px;
  height: 43px;
  display: block;
  transform: translateY(-50%);
  outline: none !important;


  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: @color-white;
    display: inline-block;
    width: 13px;
    height: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity @default-transition;
  }
  &:after {
    height: 13px;
    width: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &.collapsed {
    &:after {
      opacity: 1;
    }
  }
}

/* Footer menu */
.footer{
  .menu_main{
    background-color: transparent;
    padding: 0;
    visibility: visible;
    opacity: 1;
    font-size: 1em;
    line-height: 1.85;
    display: flex;
    flex-direction: column;
    height: 120px;
    flex-wrap: wrap;
    z-index: 10;
  }
  .menu_main_item{
    display: inline-block;
    padding: 0 15px;

    &.edit-btn{
      display: none;
    }
  }
  .menu_main_link{
    padding: 0;
    color: @color-gray-lighter;
    border: none;
    transition: color @default-transition;
  }
  .menu_collapseLink{
    display: none;
  }
}

@media (min-width: @screen-lg) {
  .menu_btn {
    display: none;
  }

  .menu_main {
    display: block;
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    position: absolute;
    left: 350px;
    top: 37px;
    max-width: 820px;
    width: 100%;
    font-size: 1em;
    padding: 0;
  }

  .menu_main_item {
    display: inline-block;
    padding: 0 10px;

    &.edit-btn{
      padding: 0;
    }
  }

  .menu_main_link {
    padding: 0;
    border: none;
    margin-bottom: 10px;
    transition: color @default-transition;

    &:hover {
      color: @color-gray-lighter;
    }
  }

  .menu_collapseLink {
    display: none;
  }

  .footer{
    .menu_main{
      display: block;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      height: auto;
      padding: 0 17px;
    }
    .menu_main_item{
      padding: 0 11px;
    }
    .menu_main_link{
      &:hover{
        color: @color-purple-lighter;
      }
    }
  }
}