.menu_sub2MenuCont{
  display: block;
  font-size: 1rem;
  padding: 0;
}
.menu_sub2MenuItem{
  display: block;
  text-transform: none;
}
.menu_sub2MenuLink{
  display: block;
  color: @color-white;
  padding: 6px 20px 18px 120px;
  &:hover,
  &:focus{
    color: @color-white;
  }
  &.active{
    font-weight: bold;
  }
}

