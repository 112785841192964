.menu_subMenuCont{
  display: block;
  font-size: 1rem;
  padding: 0;
}
.menu_subMenuItem{
  display: block;
  text-transform: none;
  position: relative;
}
.menu_subMenuLink{
  display: block;
  color: @color-white;
  padding: 6px 20px 18px 85px;
  &:hover,
  &:focus{
    color: @color-white;
  }

  &.active{
    font-weight: bold;
  }
}

.menu_subMenuLinkCollapse{
  position: absolute;
  top: -4px;
  right: 5px;
  width: 43px;
  height: 43px;
  display: block;
  outline: none !important;

  &:before,
  &:after{
    content: '';
    position: absolute;
    background-color: @color-white;
    display: inline-block;
    width: 13px;
    height: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity @default-transition;
  }
  &:after{
    height: 13px;
    width: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &.collapsed{
    &:after{
      opacity: 1;
    }
  }
}

@media (min-width: @screen-lg){
  .menu_subMenu,
  .menu_subMenu.collapse.in{
    display: none;
  }
}