.sideMenu{
  padding: 0;
  border: 2px solid @color-gray-lighter;
  font-size: 1.142857em;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 50;
  background-color: #fff;
}
.sideMenu_item{
  position: relative;
  display: block;

  &.opened{
    .sideMenu_link{
      border-color: transparent;
    }
  }
}
.sideMenu_itemCont{
  padding: 0;
  margin: 0;

  .sideMenu_item:last-child{
    .sideMenu_link{
      border:none;
    }
  }
}
.sideMenu_link{
  display: block;
  padding: 12px 0 12px 15px;
  transition: border-color @default-transition, color @default-transition;
  &:hover{
    color: @color-purple-lighter;
  }
}
.sideMenu_link-selected,
.sideMenu_subLink-selected{
  color: @color-purple-lighter;
  font-weight: 500;
}
.sideMenu_collapseLink{
  position: absolute;
  top: 26px;
  right: 20px;
  width: 20px;
  height: 20px;
  display: block;
  transform: translateY(-50%);
  outline: none !important;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: @color-gray-lighter;
    display: inline-block;
    width: 10px;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity @default-transition;
  }
  &:after {
    height: 10px;
    width: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &.collapsed {
    &:after {
      opacity: 1;
    }
  }
}
.sideMenu_subMenu{
  padding: 0;
}
.sideMenu_subItem{
  display: block;
}
.sideMenu_subLink{
  padding: 12px 0 12px 50px;
  display: block;
  &:hover{
    color: @color-purple-lighter;
  }
}

@media (min-width: @screen-lg){
  .sideMenu{
    border: none;
    font-size: 1em;
    position: static;
  }
  .sideMenu_link{
    border-bottom: 2px solid @color-gray-lighter;
    padding-left: 27px;
  }
  .sideMenu_collapseLink-mainCollapser{
    display: none;
  }
  .sideMenu_item-selected{
    &:before{
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      left: 0;
      top: 25px;
      transform: translateY(-50%);
      background-color: @color-gray-lighter;
    }
  }
  .sideMenu_subLink {
    padding-left: 62px;
  }
}