.section{
  margin-bottom: 40px;
}
.sidebar .section:last-child{
  margin-bottom: 0;
}
.section-moreSpace{
  margin-bottom: 50px;
}
.section-lessBottomSpace{
  margin-bottom: 24px;
}

@media (min-width: @screen-lg){
  .sidebar .section:last-child{
    margin-bottom: 40px;
  }
  .section-half {
    width: 50%;
    float: left;
  }
  .section-stretchLeft{
    margin-left: -30px;
  }
  .section-pullBottom{
    margin-bottom: -76px;
  }
  .section-newsFront,
  .section-eventsFront {
    width: 50%;
    position: absolute;
  }
  .section-newsFront{
    left: 0;
    top: -404px;
  }
  .section-eventsFront {
    right: 0;
    top: -202px;

    .gridFront_title{
      color: @color-white;
    }
  }
  .section-stretchLeft{
    margin-left: -30px;
  }
}

