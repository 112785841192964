.placeholder{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 906px;
  color: @color-purple;
  text-align: center;
  font-weight: 500;
  font-family: 'AvenirLTStd', sans-serif;
  padding: 0 10px;
  letter-spacing: 0;

  a{
    color: @color-purple;
    display: inline-block;
    position: relative;
    &:hover{
      color: @color-gray-darker;
      &:after{
        border-color: @color-gray-darker;
      }
    }
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      border-bottom: 1px solid @color-purple;
      bottom: 7px;
      left: 0;
    }
  }
}
.placeholder_title{
  font-size: 48px;
  margin-bottom: 39px;
  line-height: 1;
  color: @color-purple;

  strong{
    font-weight: 900;
  }
}
.placeholder_text{
  font-size: 18px;
  max-width: 590px;
  margin: auto;
  line-height: 2;
}

@media (max-height: 350px){
  .placeholder{
    top: 0;
    left: 0;
    transform: none;
  }
}