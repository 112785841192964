.lang {
  display: block;
  padding: 0;
  position: absolute;
  right: 143px;
  top: 37px;
  font-size: 1.1428571em;
}

.lang_item {
  display: inline-block;
  & + .lang_item {
    margin-left: 5px;
  }
  &.edit-btn {
    margin-left: 10px;
  }
}

.lang_link {
  color: @color-white;

  &.active {
    color: @color-gray-lighter;
  }
}

@media (min-width: @screen-lg){
  .lang {
    right: auto;
    left: 0;
    width: 315px;
    text-align: right;
    padding: 0 45px 0 10px;
    font-size: 1em;
  }
  .lang_link {
     color: @color-gray-darker;

     &.active {
       color: @color-gray-darker;
       font-weight: bold;
     }
   }

}