html{
  font-size: 16px;
}
body, html{
  height: 100%;
  min-height: 350px;
}
body{
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
}
img{
  width: 100%;
  height: auto;
}
.voog-search-modal{
  width: 280px !important;
  z-index: 102;
}

h1,h2,h3,h4{
  color: @color-gray-darker;
  margin-top: 0.6944em;
  margin-bottom: 0.6944em;
  line-height: inherit;
}
h1{
  font-size: 1.875em;
  margin-bottom: 0;
}
h2{
  font-size: 2.25em;
  line-height: 1.3;
  margin-top: 0.4444em;
  margin-bottom: 0.527778em;
}
h3{
  font-size: 1.125em;
  line-height: 1.3;
}
a{
  transition: color @default-transition;
}

.edy-positionable-container-center,
.edy-texteditor-container-wrapper-center{
  margin-bottom: 15px;
}
.edy-positionable-container-left,
.edy-positionable-container-left-block,
.edy-texteditor-container-wrapper-left,
.edy-texteditor-container-wrapper-left-block{
  margin-right: 15px;
  margin-bottom: 15px;
}
.edy-positionable-container-right,
.edy-positionable-container-right-block,
.edy-texteditor-container-wrapper-right,
.edy-texteditor-container-wrapper-right-block {
  margin-left: 15px;
  margin-bottom: 15px;
}

@media (min-width: @screen-sm){
  body{
    font-size: 1rem;
    /*line-height: 1.3;*/
  }
}