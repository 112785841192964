.elem{

}
.elem_imageCont{
  float: left;
  width: 50%;
  max-width: 300px;
  margin: -20px 30px 20px 0;

  img{
    max-height: none !important;
  }
  .edy-element-thumb-catcher{
    width: auto;
  }
  .edy-element-thumb-empty{
    height: 400px;
  }
}
.elem_image{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 133.333333%;
}
.elem_title{
  color: @color-gray-darkest;
  margin-bottom: 0.766667em;
}
.elem_subTitle{
  font-size: 0.533333em;
  display: block;
  font-weight: 400;
  margin-top: 5px;
}
.elem_content{
  a{
    color: @color-gray-darkest;
    font-weight: bold;
  }
}
@media (min-width: @screen-lg){
  .elem_imageCont{
    margin-left: -30px;
  }
}