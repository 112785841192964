.content{
  margin-top: 20px;
}

.front-page{
  .content{
    margin-top: 0;
  }
}

@media (min-width: @screen-lg){
  .content{
    /*margin: 0;*/
  }
}