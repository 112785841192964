.header{
  position: relative;
}
.header_links{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.header_image{
  height: 0;
  float: right;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: @color-gray-darker;
  padding-bottom: 248px;
  position: relative;
}
.header_pageTitle{
  background-color: @color-white;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 22px 29px 0;
  min-width: 241px;
  font-size: 1.714286em;
  line-height: 0.9;
  &:before{
    content: '';
    position: absolute;
    height: 100%;
    width: 300px;
    background-color: @color-white;
    right: 100%;
    top: 0;
  }
}
.front-page{
  .header_pageTitle{
    display: none;
  }
  .header_image{
    padding-bottom: 60.2%;
  }
}
.header_logo{
  position: absolute;
  left: 29px;
  top: 26px;
  width: 131px;
  height: 49px;
  z-index: 101;
}
.header_logo_img-desktop{
  display: none;
}
.header_logo_img-mobile{
  display: block;
}

.header_imagePicker{
  position: absolute !important;
  right: 0;
  top: 150px;
  height: 150px;
  width: 150px;
}
.header_image_bgPicker{
  right: 0;
  left: auto;
  margin: 70px 10px 0 0 !important;
}

@media (min-width: @screen-sm){
  .header_logo{
    left: 56px;
    top: 20px;
    width: 160px;
  }
  .header_image{
    padding-bottom: 335px;
  }

  .front-page{
    .header_image{
      padding-bottom: 555px;
    }
  }
}
@media (min-width: @screen-lg) and (max-width: 1277px){
  /*.header_image{
    width: 73.81% !important;
  }*/
}
@media (min-width: @screen-lg){
  .header_image {
    width: calc(~"50% + 300px");
  }
  .header_pageTitle{
    padding-top: 39px;
    min-width: 300px;
    line-height: 0.7;
    font-size: 1.875em;
    left: calc(~"50% - 300px");

    &:before{
      display: none;
    }
  }
  .front-page{
    .header_image{
      padding-bottom: 800px;
    }
  }
  .header_logo{
    left: 91px;
    top: 126px;
    width: 168px;
  }
  .header_logo_img-desktop{
    display: block;
  }
  .header_logo_img-mobile{
    display: none;
  }
}