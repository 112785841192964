.blogLatest {
  color: @color-gray-lighter;
  padding-left: 0.4375rem;
  display: none;
}

.blogLatest-mobile {
  display: block;
  padding-left: 0;
}

.blogLatest_title {
  margin-bottom: 0.25rem;
  font-size: 1em;
}

a.blogLatest_link {
  display: block;
  color: @color-gray-lighter;
}

.blogLatest_heading {
  color: @color-gray-darker;
  font-weight: 500;
}

.blogLatest_date {
  font-size: 0.69em;
}

.front-page {
  .blogLatest_container {
    padding-left: 30px;
  }
}

@media (min-width: @screen-sm) {
  .blogLatest {
    font-size: 1.3125em;
  }
}

@media (min-width: @screen-lg) {
  .blogLatest {
    font-size: 1.125em;
    display: block;
  }

  .blogLatest-mobile {
    display: none;
  }

  .blogLatest_heading {
    font-size: 1rem;
  }
}