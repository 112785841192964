/**
 * @license
 * MyFonts Webfont Build ID 3507861, 2018-01-10T08:18:01-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed /assets(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: CoreSansA45Regular by S-Core
 * URL: https://www.myfonts.com/fonts/s-core/core-sans-a/regular/
 * Copyright: Core Sans A 45 Regular
 *
 * Webfont: CoreSansA55Medium by S-Core
 * URL: https://www.myfonts.com/fonts/s-core/core-sans-a/medium/
 * Copyright: Core Sans A 55 Medium
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3507861
 * Licensed pageviews: 10,000
 *
 * © 2018 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/358695");

@font-face {
  font-family: 'CoreSansA';
  src: url('/assets/358695_0_0.eot');
  src: url('/assets/358695_0_0.eot?#iefix') format('embedded-opentype'), url('/assets/358695_0_0.woff2') format('woff2'), url('/assets/358695_0_0.woff') format('woff'), url('/assets/358695_0_0.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'CoreSansA';
  src: url('/assets/358695_1_0.eot');
  src: url('/assets/358695_1_0.eot?#iefix') format('embedded-opentype'), url('/assets/358695_1_0.woff2') format('woff2'), url('/assets/358695_1_0.woff') format('woff'), url('/assets/358695_1_0.ttf') format('truetype');
  font-weight: 500;
}


@import url("//hello.myfonts.net/count/30ee6c");

@font-face {
  font-family: 'AvenirLTStd';
  src: url('/assets/30EE6C_0_0.eot');
  src: url('/assets/30EE6C_0_0.eot?#iefix') format('embedded-opentype'), url('/assets/30EE6C_0_0.woff2') format('woff2'), url('/assets/30EE6C_0_0.woff') format('woff'), url('/assets/30EE6C_0_0.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('/assets/30EE6C_1_0.eot');
  src: url('/assets/30EE6C_1_0.eot?#iefix') format('embedded-opentype'), url('/assets/30EE6C_1_0.woff2') format('woff2'), url('/assets/30EE6C_1_0.woff') format('woff'), url('/assets/30EE6C_1_0.ttf') format('truetype');
  font-weight: 900;
}